









































































































































import { Vue, Component } from 'vue-property-decorator'
import { StateEnum } from '@/store/entities/state-enum'
import MeetingModule from '@/store/modules/meeting'
import BaseDataModule from '@/store/modules/base-data'

@Component
export default class EventPage extends Vue {
  isLoading: boolean = true;

  get meetingDate() {
    if (!this.currentInfo.repeatStartDate) {
      return this.currentInfo.singleDate;
    } {
      return `${this.currentInfo.repeatStartDate} ~ ${this.currentInfo.repeatEndDate}`;
    }
  }

  get isShowCancel() {
    /*先决条件 草稿、待审批、审批通过*/
    let isMatchState = (this.currentInfo.status == StateEnum.Draft ||
      this.currentInfo.status == StateEnum.Pending ||
      this.currentInfo.status == StateEnum.Adopt);

    if (!isMatchState) return false;

    let _currentUser = BaseDataModule.currentUser;

    /* 是否 管理员 */
    if (_currentUser.isAdmin) return true;

    /* 是否 本人 */
    if (this.currentInfo.subscriberId == Number(_currentUser.employeeID)) return true;

    return false;
  }

  get isShowSubmit() {
    /*草稿才显示提交*/
    return this.currentInfo.status == StateEnum.Draft;
  }

  get currentInfo() {
    return MeetingModule.currentInfo;
  }

  async created() {
    await MeetingModule.get({ id: this.$route.params.id });
    setTimeout(() => {
      this.isLoading = false;
    }, 300);
  }

  async cancelMeeting() {
    this.$confirm("确认取消会议？", "提示", {
      confirmButtonText: '确定',
      cancelButtonText: '取消',
      type: 'warning'
    }).then(async () => {
      await MeetingModule.cancelMeeting({ meetingId: this.currentInfo.id }).then((res) => {
        this.$message.success("操作成功");
        window.location.reload();
      }).finally(() => {
        this.isLoading = false;
      });
    }).catch(() => {
      this.isLoading = false;
    });
  }

  async submitDraft() {
    this.$confirm("确认提交审批？", "提示", {
      confirmButtonText: '确定',
      cancelButtonText: '取消',
      type: 'warning'
    }).then(async () => {
      await MeetingModule.submitDraft({ meetingId: this.currentInfo.id }).then((res) => {
        this.$message.success("操作成功");
        window.location.reload();
      }).finally(() => {
        this.isLoading = false;
      });
    }).catch(() => {
      this.isLoading = false;
    });
  }

  close() {
    this.isLoading = true;
    this.$emit("update:isShow", false);
  }
}
